import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { envVariables } from '@app-constants/envVariables';

const { applicationVersion, bffHostUrl, dataDog, gpEnv } = envVariables;

export function initDataDog() {
  if (!dataDog.clientToken || Boolean(envVariables.integrationTestExecution)) return null;

  const datadogAttributes = {
    clientToken: dataDog.clientToken,
    site: 'datadoghq.com',
    service: 'olympus',
    env: gpEnv || 'development',
    version: dataDog.version || applicationVersion,
  };

  if (dataDog.appId) {
    datadogRum.init({
      ...datadogAttributes,
      applicationId: dataDog.appId,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      sessionSampleRate: dataDog.sampleRate,
      sessionReplaySampleRate: dataDog.replaySampleRate,
      allowedTracingUrls: [bffHostUrl],
    });
    datadogRum.startSessionReplayRecording();
  }

  return datadogLogs.init({
    ...datadogAttributes,
    forwardErrorsToLogs: true,
  });
}
